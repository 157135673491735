import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_4 = { class: "card-header border-0 pt-5" }
const _hoisted_5 = { class: "card-title align-items-start flex-column" }
const _hoisted_6 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_7 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_8 = { class: "card-body py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("pages.entities.title")), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(this.$store.getters.currentSettings.clientName), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_el_tree, {
            data: _ctx.entities,
            "default-expanded-keys": _ctx.defaultExpandedKeys,
            "node-key": "id",
            ref: "tree",
            "highlight-current": "",
            props: _ctx.defaultProps,
            onNodeExpand: _ctx.handleNodeClick,
            onNodeCollapse: _ctx.handleNodeClick
          }, null, 8, ["data", "default-expanded-keys", "props", "onNodeExpand", "onNodeCollapse"])
        ])
      ])
    ])
  ]))
}